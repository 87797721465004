import { PUBLIC_DIFFUSION_API_ENDPOINT } from '$env/static/public';
import {
	Client as DiffusionApiClient,
	type CreateEarlyAccessInviteResponse,
	type GetEarlyAccessInvitesResponse
} from '$lib/diffusionApi';

export default class EarlyAccessInvitesClient {
	private diffusionApiClient: DiffusionApiClient;

	constructor(token: string) {
		this.diffusionApiClient = new DiffusionApiClient({
			BASE: PUBLIC_DIFFUSION_API_ENDPOINT,
			TOKEN: token
		});
	}

	public setToken(token: string) {
		this.diffusionApiClient = new DiffusionApiClient({
			BASE: PUBLIC_DIFFUSION_API_ENDPOINT,
			TOKEN: token
		});
	}

	public async getEarlyAccessInvites(): Promise<GetEarlyAccessInvitesResponse | undefined> {
		try {
			return await this.diffusionApiClient.default.getEarlyAccessInvitesEarlyAccessInvitesGet();
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	public async createEarlyAccessInvite(
		invitee_email: string
	): Promise<CreateEarlyAccessInviteResponse | undefined | string> {
		try {
			return await this.diffusionApiClient.default.createEarlyAccessInviteEarlyAccessInvitePost({
				invitee_email
			});
		} catch (error) {
			console.error(error);
			if (error?.body?.detail) {
				return error.body.detail;
			}
		}
	}
}
