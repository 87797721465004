<script lang="ts">
	import { onMount, onDestroy } from 'svelte';
	import { signOut } from 'firebase/auth';
	import { Menu, MenuButton, MenuItems, MenuItem } from '@rgossiaux/svelte-headlessui';
	import { auth } from '$lib/firebase';
	import authStore from '$lib/authStore';
	import EarlyAccessInvitesClient from '$lib/earlyAccessInvitesClient';

	export let light = true;
	export let earlyAccessInviteCount: number = 0;
	export let fixed: boolean = false;
	const earlyAccessInviteClient = new EarlyAccessInvitesClient('');

	let subscriptions: (() => void)[] = [];

	async function logout() {
		try {
			await signOut(auth);
		} catch (error: any) {
			console.log(error);
		}
	}

	const getEarlyAccessInvites = async () => {
		const response = await earlyAccessInviteClient.getEarlyAccessInvites();
		if (response === undefined) {
			return;
		}
		earlyAccessInviteCount = response.early_access_invite_count;
	};

	onMount(() => {
		subscriptions.push(
			authStore.subscribe(async (auth) => {
				const user = auth.user;
				if (user !== null && user !== undefined) {
					earlyAccessInviteClient.setToken(await user.getIdToken());
					await getEarlyAccessInvites();
				}
			})
		);
	});

	onDestroy(() => {
		for (const _ of subscriptions) {
			_();
		}
	});
</script>

<div class="flex flex-row">
	{#if $authStore.isLoggedIn}
		<div class="flex flex-row justify-start items-center">
			<Menu>
				<MenuButton
					class={`
						w-8 h-8 flex flex-row justify-center items-center text-md rounded
						${
							light
								? `text-primary-600 bg-primary-100 hover:bg-primary-200`
								: `text-zinc-400 border border-zinc-800 hover:border-zinc-700 bg-zinc-900 hover:bg-zinc-800 hover:text-zinc-300 shadow-sm`
						}	
					`}
				>
					<i class="fa-solid fa-bars" />
				</MenuButton>
				<MenuItems
					class={`${
						fixed ? 'fixed' : 'absolute'
					} top-10 left-2 w-52 h-fit flex flex-col justify-start items-center text-zinc-100 bg-zinc-900 rounded border border-zinc-800 text-xs shadow-md`}
				>
					<div class="h-1.5" />
					<MenuItem disabled class="w-[196px] h-fit p-2 overflow-x-hidden">
						Signed in as
						<div class="h-0.5" />
						<p>
							<b>{$authStore.user?.displayName ? $authStore.user?.displayName : ''}</b>
						</p>
						<p>
							{$authStore.user?.email}
						</p>
					</MenuItem>
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/home" class="block w-full p-2"
							><i class="fa-solid fa-house pl-1 pr-2.5" />Home</a
						>
					</MenuItem>
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/workspace" class="block w-full p-2"
							><i class="fa-solid fa-paintbrush text-cyan-400 pl-1 pr-2.5" />Image workspace</a
						>
					</MenuItem>
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/history" class="block w-full p-2"
							><i class="fa-solid fa-images text-cyan-400 pl-1 pr-2.5" />Image history</a
						>
					</MenuItem>
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/voxel-art-generator" class="block w-full p-2"
							><i class="fa-solid fa-cubes text-pink-400 pl-1 pr-2.5" />Voxel art generator</a
						>
					</MenuItem>
					<div class="h-1.5" />
					<!-- <div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active ? 'w-[196px] h-fit bg-zinc-800 rounded' : 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/world-generator" class="block w-full p-2">
							<i class="fa-solid fa-scroll text-stone-400 pl-1 pr-2.5" />World generator</a
						>
					</MenuItem>
					<div class="h-1.5" /> -->
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/early-access-invites" class="block w-full p-2">
							<i
								class={`fa-solid fa-envelope ${
									earlyAccessInviteCount > 0 ? `text-emerald-400` : ``
								} pl-1 pr-2`}
							/>
							Early access invites ({earlyAccessInviteCount})
						</a>
					</MenuItem>
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/subscription" class="block w-full p-2"
							><i class="fa-solid fa-tags pl-1 pr-2.5" /> Manage subscription</a
						>
					</MenuItem>
					<div class="h-1.5" />
					<div class="w-full h-0 border-t border-zinc-800" />
					<div class="h-1.5" />
					<MenuItem
						class={({ active }) =>
							active
								? 'w-[196px] h-fit bg-zinc-800 rounded'
								: 'w-[196px] h-fit bg-zinc-900 rounded'}
					>
						<a href="/" class="block w-full p-2" on:click={logout}
							><i class="fa-solid fa-sign-out pl-1.5 pr-2.5" />Sign out</a
						>
					</MenuItem>
					<div class="h-1.5" />
				</MenuItems>
			</Menu>
		</div>
	{:else}
		<div class="w-8 h-8" />
	{/if}
</div>
